// react
import React, { useState } from 'react';

// redux
// import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

// components
import Popup from 'reactjs-popup';
import Header from 'components/Header';
import Todos from 'components/Todos';
import AddTodo from 'components/AddTodo';
import TodoDetail from 'components/TodoDetail';

// styles
import './App.css';

const sendEmail = (nylas) =>
{
	const draft = nylas.drafts.build(
	{
		subject: 'email #2',
		body: 'Test with 1st Client ID',
		to: [{ name: 'My Nylas Friend', email: 'adrien@twelve.tw' }]
	});

	draft.send().then(message => {
			console.log(`${message.id} was sent`);
	});
};

const App = ({ nylas }) =>
{
	const [selectedTotoId, setSelectedTotoId] = useState('');
	const [popupOpened, setPopupOpened] = useState(false);

	return (
		<div className="App">

			<Header />

			<main id="content">
				<button onClick={() => sendEmail(nylas)}>Send eMail</button>

				<AddTodo />

				<Todos onTodoClick={(id) => { setSelectedTotoId(id); setPopupOpened(true); } }/>

				<Popup
					open={popupOpened}
					// closeOnDocumentClick
					onClose={() => setPopupOpened(false)}
					position="top center"
					contentStyle={{ backgroundColor: 'black' }}
					modal={false}
				>
					<TodoDetail id={selectedTotoId} />
				</Popup>
			</main>
		</div>
	);
}

export default App;
