/* eslint-disable react/jsx-filename-extension, indent */

// react
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore' // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable

// redux
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
// import { reduxFirestore, firestoreReducer } from 'redux-firestore';


// components
import App from './App';

// css
import './index.css';

const firebaseConfig =
{
	apiKey: "AIzaSyByXJg6OJzqiBQWFZreQ4142VteLKW5GSo",
	authDomain: "mess-ms.firebaseapp.com",
	databaseURL: "https://mess-ms.firebaseio.com",
	projectId: "mess-ms",
	storageBucket: "mess-ms.appspot.com",
	messagingSenderId: "1054822518347",
	appId: "1:1054822518347:web:4edf0ca73146bab55887ce",
	measurementId: "G-E2HZNNWRD8"
};

// react-redux-firebase config
const reactReduxFirebaseConfig =
{
	userProfile: 'users',
	useFirestoreForProfile: true // use Firestore instead of realtime DB
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
// auth ?
firebase.firestore();
// firebase.functions(); // <- needed if using httpsCallable


// Add reduxFirestore store enhancer to store creator (firebase instance as first argument, rfConfig as optional second);
// const reduxFirestoreConfig = {}; // optional redux-firestore Config Options
// const createStoreWithFirebase = compose(reduxFirestore(firebase, reduxFirestoreConfig))(createStore);


// Add firebase to reducers
const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer
})

// Create store with reducers and initial state
const initialState = {}
// const store = createStoreWithFirebase(rootReducer, initialState)
const store = createStore(rootReducer, initialState)

const rrfProps = {
	firebase,
	config: reactReduxFirebaseConfig,
	dispatch: store.dispatch,
	createFirestoreInstance // <- needed if using firestore
}

// Nylas setup
const Nylas = require('nylas');
// const CLIENT_ID = 'a7onjm6qalm4e4ozwxrog9fia';
// const CLIENT_SECRET = '9jg4l5jjqx2h0ff5iavginaoz';
// const ACCESS_TOKEN = 'Vuj4CQLeX4BJWX6sYoo4tJel9bZ2fv';
const CLIENT_ID = 'XXXXXXXXXXXXXXXXXXXXXXXXXX';
const CLIENT_SECRET = 'XXXXXXXXXXXXXXXXXXXXXXXXXX';
const ACCESS_TOKEN = 'XXXXXXXXXXXXXXXXXXXXXXXXXX';
Nylas.config({
	clientId: CLIENT_ID,
	clientSecret: CLIENT_SECRET,
	apiServer: 'https://app.firebase.com/nylas'
});
const nylas = Nylas.with(ACCESS_TOKEN);


// Setup react-redux so that connect HOC can be used
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<App nylas={nylas}/>
			</ReactReduxFirebaseProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
