import React from 'react';
import { useFirestore } from 'react-redux-firebase';

const AddTodo = () =>
{
	const firestore = useFirestore();

	function addSampleTodo()
	{
		const sampleTodo = { text: 'Sample', done: false };
		// return firebase.push('todos', sampleTodo)
		// return firestore.add({collection: 'todos', doc: 'baz'}, { greetings: 'Lorem ipsum' }, []);
		firestore.collection('todos').add(sampleTodo);
		// firestore.add({ collection: 'todos' }, sampleTodo);
		// firestore.add('/todos', sampleTodo);
		// firestore.set('users/myuid/todos/randomid', {id:'randomid', task: 'eat'})
	};

	return (
		<div>
			<button onClick={addSampleTodo}>Add</button>
		</div>
	)
};

export default AddTodo;