
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
// import { useFirestore, isLoaded, isEmpty } from 'react-redux-firebase'

import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'

// Firebese web UI (Auth)
import { StyledFirebaseAuth } from 'react-firebaseui';
import { auth as firebaseuiAuth } from 'firebaseui';
import firebase from 'firebase/app';

// components
import Popup from 'reactjs-popup';

// images
import spinner from 'images/spinner.svg';
import accountUnlogged from 'images/account-unlogged.svg';
import accountLogged from 'images/account-logged.svg';
import arrow from 'images/arrow-down.svg';

// FirebaseUI conf
const firebaseUiConfig =
{
	credentialHelper: firebaseuiAuth.CredentialHelper.NONE, // do not use accountChooser (remember saved accounts)
	signInFlow: 'popup', // use popup vs redirect
	signInSuccessUrl: '/signedIn',
	signInOptions: [
		firebaseuiAuth.AnonymousAuthProvider.PROVIDER_ID, // continue as guest
		firebase.auth.EmailAuthProvider.PROVIDER_ID,
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.FacebookAuthProvider.PROVIDER_ID
	],
	callbacks: { signInSuccessWithAuthResult: () => false } // Avoid redirects after sign-in.
};

const Spinner = () => <img className="spinner" src={spinner} alt="loading"></img>;

const AccountButton = React.forwardRef(({ open, loading, authorized, ...props }, ref) => (
	<div id="account-button" ref={ref} {...props}>
		{loading && <Spinner />}
		{!loading && !authorized && <img src={accountUnlogged} alt="account"></img>}
		{!loading && authorized && <img src={accountLogged} alt="account"></img>}
		<img src={arrow} alt="down"></img>
	</div>
));

class ErrorBoundary extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info)
	{
		// Display fallback UI
		this.setState({ hasError: true }); // You can also log the error to an error reporting service
		// console.log(error, info);
	}
	render()
	{
		if (this.state.hasError) return <div>x</div>; // fallback UI
		else return this.props.children;
	}
}

const AccountMenu = () =>
{
	const reduxFirebase = useFirebase();
	const auth = useSelector(state => state.firebase.auth)

	const loading = !isLoaded(auth);
	const authorized = !isEmpty(auth);

	return (
		<div id="account">
			<ErrorBoundary>
				<Popup
					className="account-popup"
					trigger={open => <AccountButton open={open} loading={loading} authorized={authorized} />}
					position="bottom right"
					on="hover"
					closeOnDocumentClick
					mouseLeaveDelay={300}
					mouseEnterDelay={0}
					contentStyle={{ padding: "0px", border: "none" }}
					arrow={false}
				>
					<div>
						{loading && <Spinner />}

						{!loading && !authorized && <Popup trigger={<div>Sign in</div>} modal closeOnDocumentClick>
								<StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={reduxFirebase.auth()}/>
							</Popup>
						}

						{!loading && authorized && <pre>{JSON.stringify(auth, null, 2)}</pre>}
					</div>
				</Popup>
			</ErrorBoundary>
		</div>);
};

export default AccountMenu;


