import React from 'react'
// import { useSelector } from 'react-redux'
// import { useFirestore, isLoaded, isEmpty } from 'react-redux-firebase'

// components
import AccountMenu from 'components/AccountMenu';

// images
import hamburger from 'images/hamburger.svg';
import compose from 'images/edit.svg';
import unread from 'images/unread.svg';
import sent from 'images/sent.svg';
import starred from 'images/starred.svg';
import trash from 'images/trash.svg';
import logo from 'images/logo.svg';

// font
import 'typeface-pacifico';

const Hamburger = () => <div id="menu"><img src={hamburger} alt="menu"></img></div>;

const Toolbar = () => (
	<div id="toolbar">
		<button id="compose"><img src={compose} alt="new"></img></button>
		<button id="unread"><img src={unread} alt="unread"></img></button>
		<button id="sent"><img src={sent} alt="sent"></img></button>
		<button id="starred"><img src={starred} alt="starred"></img></button>
		<button id="trash"><img src={trash} alt="trash"></img></button>
	</div>
);

const Header = () =>
{
	return (
		<nav className="App-header">

			<div className="nav-centered">
				<Hamburger />
				<Toolbar />
			</div>

			<div className="nav-centered">
				<a id="header-logo" href="index">
					<img src={logo} alt="logo"></img>
					<span className="logo-text">mess</span><span className="logo-text-sub">.ms</span>
				</a>
			</div>

			<div className="nav-centered">
				<AccountMenu />
			</div>
		</nav>
		);
};

export default Header;