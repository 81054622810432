
import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestore, isLoaded, isEmpty } from 'react-redux-firebase'


const TodoDetail = ({ id }) =>
{
	// useFirestoreConnect([{ collection: 'todos', doc: id }]) // do not useFirestoreConnect here because it is already called on parent App). otherwise store will be filtered by selector for parent also.
	// const todo = useSelector(state => state.firestore.data.todos[id]); <= does not work
	const todo = useSelector(({ firestore: { data } }) => data.todos && data.todos[id]);


	const firestore = useFirestore();
	const toggleDone = () => { firestore.update(`todos/${id}`, { done: !todo.done }); }

	if (!isLoaded(todo)) return <span>loading...</span>;

	else return (
		<div>
			<h1>{todo.text}</h1>
			<button onClick={toggleDone}>{todo.done ? 'Done' : 'Undone'}</button>
			<p>{todo.note}</p>
		</div>);
};

export default TodoDetail;