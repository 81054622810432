import React from 'react';
import { useFirestore } from 'react-redux-firebase';

const RemoveTodo = ({ id }) =>
{
	const firestore = useFirestore();

	const removeTodo = () =>
	{
		// firestore.collection('todos').delete(id);
		firestore.delete(`todos/${id}`);
	};

	return (
		<div>
			<button onClick={removeTodo}><span role="img" aria-label="delete">❌</span></button>
		</div>
	)
};

export default RemoveTodo;