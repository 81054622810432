import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded, isEmpty  } from 'react-redux-firebase';

// components
import RemoveTodo from 'components/RemoveTodo';


const TodoItem = ({ id, text, done, onClick }) => <li>id: {id} todo: {text} done: {done ? 'yes' : 'no'} <button onClick={onClick}>show</button> <RemoveTodo id={id}/> </li>;

// const DataTodos = () =>
// {
// 	// sync todos collection from Firestore into redux
// 	useFirestoreConnect('todos'); // useFirestoreConnect([{ collection: 'todos' }]);

// 	// Connect to redux state using selector hook
// 	const todos = useSelector(state => state.firestore.data.todos);

// 	if (!isLoaded(todos)) return <span>loading...</span>;
// 	if (isEmpty(todos)) return <span>not todos</span>;

// 	// else return <pre>{JSON.stringify(todos, null, 2)}</pre>;
// 	else return <ul>{Object.keys(todos).map(id => <TodoItem id={id} text={todos[id].text} done={todos[id].done} />)}</ul>;
// };


const OrderTodos = ({ onTodoClick }) =>
{
	// sync todos collection from Firestore into redux
	useFirestoreConnect('todos');

	// Connect to redux state using selector hook
	const todos = useSelector(state => state.firestore.ordered.todos);

	if (!isLoaded(todos)) return <span>loading...</span>;
	else if (todos && todos.length === 0) return <span>no messages</span>;
	else if (todos && todos.length > 0) return <ul>{todos.map(todo => (
		<TodoItem
			key={todo.id}
			id={todo.id}
			text={todo.text}
			done={todo.done}
			onClick={() => onTodoClick(todo.id)}
		/>))}</ul>;
};

export default OrderTodos;
